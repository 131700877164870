<template lang="pug">
    v-container.custom-container()
        v-layout(column align-center)
            v-layout(column xs12='' align-center).pt-2.ma-2.mt-3.text-center
                v-img( cover max-width='600px' max-height='300px' :src="image")
                .title.mt-2(style="color: rgb(76,76,76); font-size: 2.2rem !important; letter-spacing: -1.5px !important;") {{title}}
                v-divider.primary.mt-5( style="width: 100px;")

            v-layout(column style="font-size: 15px;" ).text-xs-justify.pa-3

                p.py-2.text-justify
                  h2.mr-2 About
                  | Our vision is to become a voice for the community and a destination to finder evidence-based content for insurance companies and others.
                p.py-2.text-justify
                  h2.mr-2 Our name
                  | Scene Finder stands for finding the scene of a specific event. Our goal is to secure content that average citizens can use to prove their claim. Our site is a database platform of evidence-based content to assist others in clarifying the facts in their claim.
                p.py-2.text-justify
                  h2.mr-2 Purpose
                  | We strive to make a better future for our community. Honesty is the best policy and so we are committed to finding the proof and maintaining the highest standard of integrity for our customers.
                p.py-2.text-justify
                  h2.mr-2 Process
                  |  We work with 3rd parties to obtain footage to assist the community in their search to obtain evidence to prove the facts of their claim and maintain their integrity. Those in need of the footage would search our database for the content; once located they would purchase it and obtain the footage. After the content is purchased, we reward our 3rd parties for uploading the data.

</template>

<script>
  import image from '@/assets/images/static/aboutus.jpg'
  export default {
    data () {
      return {
        title: 'About us',
        image: image,
      }
    },

    computed: {

    },

  }
</script>

<style lang='scss' scoped>

</style>
